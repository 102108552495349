import React from 'react'
import Loadable from 'react-loadable'

export default function createLoadable (opts) {
  const Comp = Loadable(
    Object.assign(
      {
        loading: () => <p>加载中...</p>,
        delay: 300,
        timeout: 5000,
      },
      opts
    )
  )

  return props => <Comp {...props} />
}
