import { init } from '@rematch/core'
// import immerPlugin from '@rematch/immer'
import createRematchPersist from '@rematch/persist'
// import selectPlugin from '@rematch/select'
import { connectRouter, routerMiddleware } from 'connected-react-router'

import * as models from './models'

const isDev = process.env.NODE_ENV === 'development'

const persistPlugin = createRematchPersist({
  whitelist: ['modelName1'],
  throttle: 5000,
  version: 1,
})

const configureStore = (history) => {
  const reducers = { router: connectRouter(history) }

  const middlewares = [routerMiddleware(history)]
  if (isDev) {
    const { createLogger } = require('redux-logger')
    const logger = createLogger({ collapsed: true })
    middlewares.push(logger)
  }

  const store = init({
    redux: {
      reducers,
      middlewares,
      devtoolOptions: {
        disabled: !isDev,
      },
    },
    plugins: [
      // immerPlugin(),
      // selectPlugin(),
      persistPlugin, 
    ],
    models,
  })
  return store
}

export default configureStore