import React, { Component } from 'react'
import { hot } from 'react-hot-loader/root'
import { Route, Switch } from 'react-router-dom'

import App from '~/layouts/App'
import createLoadable from '~/utils/loadable'

const AsyncHome = createLoadable({
  loader: () => import(/* webpackChunkName: 'home' */ '~/pages/Home'),
})
const AsyncP1 = createLoadable({
  loader: () =>
    import(/* webpackChunkName: 'p1' */ '~/pages/P1'),
})
const AsyncP2 = createLoadable({
  loader: () =>
    import(/* webpackChunkName: 'p2' */ '~/pages/P2'),
})
const AsyncP3 = createLoadable({
  loader: () =>
    import(/* webpackChunkName: 'p3' */ '~/pages/P3'),
})
// const AsyncP4 = createLoadable({
//   loader: () =>
//     import(/* webpackChunkName: 'p4' */ '~/pages/P4'),
// })
const AsyncP4 = createLoadable({
  loader: () =>
    import(/* webpackChunkName: 'p4' */ '~/pages/P4-1'),
})
const AsyncP5 = createLoadable({
  loader: () =>
    import(/* webpackChunkName: 'p5' */ '~/pages/P5-1'),
})
// const AsyncP5 = createLoadable({
//   loader: () =>
//     import(/* webpackChunkName: 'p5' */ '~/pages/P5'),
// })
const AsyncP6 = createLoadable({
  loader: () =>
    import(/* webpackChunkName: 'p6' */ '~/pages/P6'),
})
const AsyncP7 = createLoadable({
  loader: () =>
    import(/* webpackChunkName: 'p7' */ '~/pages/P7'),
})
const AsyncP8 = createLoadable({
  loader: () =>
    import(/* webpackChunkName: 'p8' */ '~/pages/P8-1'),
})
// const AsyncP8 = createLoadable({
//   loader: () =>
//     import(/* webpackChunkName: 'p8' */ '~/pages/P8'),
// })
const AsyncP9 = createLoadable({
  loader: () =>
    import(/* webpackChunkName: 'p9' */ '~/pages/P9'),
})
const AsyncP10 = createLoadable({
  loader: () =>
    import(/* webpackChunkName: 'p10' */ '~/pages/P10'),
})
const AsyncP11 = createLoadable({
  loader: () =>
    import(/* webpackChunkName: 'p11' */ '~/pages/P11'),
})
const AsyncP12 = createLoadable({
  loader: () =>
    import(/* webpackChunkName: 'p12' */ '~/pages/P12'),
})

class Routes extends Component {
  render () {
    return (
      <App>
        <Switch>
          <Route exact path='/' component={AsyncHome} />
          <Route path='/p1' component={AsyncP1} />
          <Route path='/p2' component={AsyncP2} />
          <Route path='/p3' component={AsyncP3} />
          <Route path='/p4' component={AsyncP4} />
          <Route path='/p5' component={AsyncP5} />
          <Route path='/p6' component={AsyncP6} />
          <Route path='/p7' component={AsyncP7} />
          <Route path='/p8' component={AsyncP8} />
          <Route path='/p9' component={AsyncP9} />
          <Route path='/p10' component={AsyncP10} />
          <Route path='/p11' component={AsyncP11} />
          <Route path='/p12' component={AsyncP12} />
        </Switch>
      </App>
    )
  }
}

export default hot(Routes)
