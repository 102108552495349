import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale-provider/zh_CN'
import React, { useEffect } from 'react'

function App ({ children }) {

  function handler () {
    ga && ga('send', 'event', {
      eventCategory: 'input',
      eventAction: 'click',
      eventLabel: window.location.pathname,
    })
  }

  useEffect(() => {
    document.addEventListener('blur', function (e) {
      // loop parent nodes from the target to the delegation node
      for (let target = e.target; target && target !== this; target = target.parentNode) {
        if (target.matches('input')) {
          handler.call(target, e)
          break
        }
      }
    }, true)
  }, [])

  return <ConfigProvider locale={zhCN}>
    <div id='app'>
      {children}
    </div>
  </ConfigProvider>
}

export default App