import 'react-app-polyfill/ie9'
import '~/styles/global.less'

import { getPersistor } from '@rematch/persist'
import { ConnectedRouter } from 'connected-react-router'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import Routes from './routes'
import { register as registerServiceWorker } from './serviceWorker'
import configureStore from './store'
import history from './store/history'

const store = configureStore(history)
const persistor = getPersistor()

const render = Routes => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  )
}

render(Routes)

registerServiceWorker()

window.routerHistory = history
